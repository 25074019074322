import { PageBackgroundImageType } from '@api/types/types';
import { cx } from '@utils/cx';
import React, { CSSProperties } from 'react';
import styles from './Section.module.scss';

type Props = {
  children: React.ReactNode;
  theme?: 'white' | 'primary';
  padding?: 'small' | 'medium' | 'large';
  backgroundImage?: PageBackgroundImageType | null;
};

export function Section({ children, theme, padding, backgroundImage }: Props) {
  return (
    <section
      className={cx(
        styles.Section,
        !backgroundImage && theme && styles[theme],
        backgroundImage && styles.backgroundImage,
        padding && styles[padding]
      )}
      style={
        backgroundImage
          ? ({
              backgroundColor: backgroundImage.dominant_color,
              '--image-1024': `url(${backgroundImage.sizes[1024]})`,
              '--image-1366': `url(${backgroundImage.sizes[1366]})`,
              '--image-2000': `url(${backgroundImage.sizes[2000]})`,
              '--image-3000': `url(${backgroundImage.sizes[3000]})`,
            } as CSSProperties)
          : undefined
      }
    >
      <div className={styles.SectionInner}>{children}</div>
    </section>
  );
}
