import { CarouselImageItemType } from '@api/types/types';
import { Link } from '@components/Link/Link';
import { cx } from '@utils/cx';
import { imageUrl } from '@utils/imageUrl';
import { isHex } from '@utils/isHex';
import React, { useEffect, useState } from 'react';
import styles from './Carousel.module.scss';

type Props = {
  images: CarouselImageItemType[];
  interval?: number;
};

export function Carousel({ images, interval = 6000 }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setActiveIndex((activeIndex + 1) % images.length);
    }, interval);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [activeIndex, images, interval]);

  return (
    <div className={styles.Carousel}>
      {images.map((item, index) => {
        const image = item.image.data;
        const className = cx(
          styles.CarouselItem,
          index === activeIndex ? styles.active : undefined
        );
        const style = {
          backgroundColor: isHex(image.dominant_color)
            ? image.dominant_color
            : undefined,
          backgroundImage: `url(${imageUrl(image.crop)
            .setRatio('2:1')
            .toString()})`,
        };
        return item.link && item.link.trim().length ? (
          <Link
            href={item.link.trim()}
            key={image.id}
            className={className}
            style={style}
            title={item.title || item.image.data.caption}
          />
        ) : (
          <div key={image.id} className={className} style={style} />
        );
      })}

      {images.length > 1 && (
        <div className={styles.CarouselNav}>
          {images.map((item, i) => (
            <div
              onClick={() => {
                setActiveIndex(i);
              }}
              style={{ left: i * 20 }}
              key={item.image.data.id}
              className={cx(
                styles.CarouselNavButton,
                i === activeIndex && styles.active
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
}
