import React from 'react';
import styles from './Row.module.scss';
import { cx } from '@utils/cx';

export function Row({ children }: { children: React.ReactNode }) {
  return <div className={styles.Row}>{children}</div>;
}

export function Column({
  children,
  hiddenOnSmallScreen,
}: {
  children: React.ReactNode;
  hiddenOnSmallScreen?: boolean;
}) {
  return (
    <div
      className={cx(
        styles.Column,
        hiddenOnSmallScreen && styles.hiddenOnSmallScreen
      )}
    >
      {children}
    </div>
  );
}
