import styles from './Button.module.css';
import { isExternalUrl } from '@utils/isExternalUrl';
import { cx } from '@utils/cx';

type Props = {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  size?: 'large';
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  align?: 'center';
};

export function Button({
  children,
  type = 'button',
  disabled,
  href,
  onClick,
  size,
  align,
}: Props) {
  const className = cx(
    styles.Button,
    size != null && styles[size],
    align && styles[align]
  );

  if (href && href.length) {
    const isExternal = isExternalUrl(href);

    if (isExternal) {
      return (
        <a
          href={disabled ? undefined : href}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
        >
          <span>{children}</span>
        </a>
      );
    }

    return (
      <a href={disabled ? undefined : href} className={className}>
        <span>{children}</span>
      </a>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
}

export function ButtonGroup({ children }: { children: React.ReactNode }) {
  return <div className={styles.ButtonGroup}>{children}</div>;
}
