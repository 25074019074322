import { isExternalUrl } from '@utils/isExternalUrl';
import NextLink from 'next/link';
import { MouseEventHandler } from 'react';

type Props = {
  children?: React.ReactNode;
  href: string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export function Link({
  children,
  href,
  disabled,
  className,
  style,
  title,
  onClick,
}: Props) {
  if (disabled) {
    return (
      <a className={className} style={style} title={title} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (href && href.length) {
    const isExternal = isExternalUrl(href);

    if (isExternal) {
      return (
        <NextLink href={href}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={className}
            style={style}
            title={title}
            onClick={onClick}
          >
            {children}
          </a>
        </NextLink>
      );
    }
  }

  return (
    <NextLink href={href}>
      <a style={style} className={className} title={title} onClick={onClick}>
        {children}
      </a>
    </NextLink>
  );
}
